export default {
  settings: '/catalog/settings/',
  addInBasket: '/catalog/cart/add/',
  removeFromBasket: '/catalog/cart/remove/',
  simplePages: '/pages/:slug/',
  getFilterCache: '/catalog/products/filter-hash/',
  facetsProducts: '/catalog/products/facets/',
  facetsCart: '/catalog/cart/facets/',
  catalogExport: '/catalog/export/',
  cartExport: '/catalog/cart/export/',
  productExport: '/catalog/export/:sku/',
  basketList: '/catalog/cart/',
  registerUser: '/user/',
  login: '/user/login/',
  logout: '/user/logout/',
  restore_password: '/user/recover-password/',
  getOriginalFileLink: '/catalog/products/download-file/',
  downloadModifiedFile: '/catalog/products/download-modified-file/',
  getAttributes: '/attributes',
};
