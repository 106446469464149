<template>
  <div
    :id="innerId"
    ref="dropdown"
    class="bp-dropdown"
    :class="{ [className]:className, 'bp-dropdown--sub': role }">
    <span
      :class="{
        [`bp-dropdown__${(role) ? 'sub' : 'btn'}`]: true,
        [`bp-dropdown__${(role) ? 'sub' : 'btn'}--disabled`]: disabled,
        [`bp-dropdown__${(role) ? 'sub' : 'btn'}--active`]: !isHidden,
        [`${className}-bp__btn`]: className,
        [`${className}-bp__btn--active`]: !isHidden, }"
      @click="_onToggle"
      @mouseenter="_onBtnEnter"
      @mouseleave="_onBtnLeave">
      <slot name="btn" />
      <slot
        v-if="isIcon"
        name="icon">
        <svg
          v-if="isLoading"
          class="bp-dropdown__icon bp-dropdown__icon--spin"
          viewBox="0 0 512 512">
          <path
            fill="currentColor"
            d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z" />
        </svg>
        <b-icon
          v-else
          :name="iconName"
          class="bp-dropdown__icon"
          color="inherit"
          :class="{ [`bp-dropdown__icon--${align}`]: align && rotateIcon }" />
      </slot>
    </span>
    <div class="bp-dropdown__helper">
      <transition name="fade">
        <div
          v-if="!isHidden"
          :id="idDropdown"
          ref="dropdownBody"
          class="bp-dropdown__body"
          :style="{ minWidth: `${width}px`, ...stylePosition }"
          :class="{ [`${className}-bp__body`]: className }"
          @click="_onBodyClick"
          @mouseenter="_onBodyEnter"
          @mouseleave="_onBodyLeave">
          <slot name="body" />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
// https://github.com/borisbutenko/bp-vuejs-dropdown
// onmousedown перенесён из created в mounded т.к document не определён при генерации насервере
// hack for ssr
// eslint-disable-next-line no-use-before-define
const HTMLElement = typeof HTMLElement === 'undefined' ? function () {} : HTMLElement;
export default {
  name: 'BpVuejsDropdown',
  props: {
    role: {
      type: String,
      required: false,
      default: '',
    },
    unscroll: {
      type: [HTMLElement, String],
      required: false,
      default: null,
    },
    align: {
      type: String,
      required: false,
      default: 'buttom',
    },
    x: {
      type: Number,
      required: false,
      default: 0,
    },
    y: {
      type: Number,
      required: false,
      default: 0,
    },
    beforeOpen: {
      type: Function,
      required: false,
      default: resolve => resolve(),
    },
    trigger: {
      type: String,
      required: false,
      default: 'click',
    },
    closeOnClick: {
      type: Boolean,
      required: false,
      default: false,
    },
    isIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    className: {
      type: String,
      required: false,
      default: '',
    },
    iconName: {
      type: String,
      required: false,
      default: 'fas fa-angle-down',
    },
    rotateIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    id: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    teleportToBody: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isHidden: true,
      isLoading: false,
      idDropdown: null,
      timeout: null,
      width: undefined,
      stylePosition: {},
      dropdownContainer: null,
    };
  },
  computed: {
    innerId() {
      // drop_ добавляется, потмоу что числовые селекторы не являются валидными
      return this.id || 'drop_' + this._uid;
    },
  },
  watch: {
    isHidden(isHidden) {
      if (this.unscroll) {
        const el = (this.unscroll instanceof HTMLElement)
          ? this.unscroll : document.querySelector(this.unscroll);
        if (el) {
          el.style.overflow = (!isHidden) ? 'hidden' : '';
        }
      }
    },
  },
  created() {
    const $root = this.$root;
    // --- hide dropdown if other dropdowns show
    // --- or document clicked
    $root.$on('bp-dropdown:open', () => { this.isHidden = true; });
    $root.$on('bp-dropdown:hide', () => { this.isHidden = true; });
    this.idDropdown = 'bp-dropdown-' + this.generateRandomId();
  },
  mounted() {
    const $root = this.$root;
    // --- hide dropdown on document click event
    if (this.trigger === 'click' && !$root['is-bp-dropdown']) {
      Object.defineProperty($root, 'is-bp-dropdown', {
        enumerable: false,
        configurable: false,
        writable: false,
        value: true,
      });
      document.onmousedown = (e) => {
        const target = e.target;
        const dropdown = target.closest('.bp-dropdown__btn') || target.closest('.bp-dropdown__body');
        if (!dropdown) {
          $root.$emit('bp-dropdown:hide');
        }
      };
    }
  },
  methods: {
    // --- generate random id for query selector
    generateRandomId() {
      return Math.random().toString(36).substr(2, 10);
    },
    _onToggle(e) {
      if (this.disabled) {
        return;
      }
      if (this.trigger !== 'click') {
        return;
      }
      this.checkCustomCallback(e);
    },
    _onBtnEnter(e) {
      if (this.disabled) {
        return;
      }
      if (this.trigger !== 'hover' || !this.isHidden) {
        return;
      }
      this.checkCustomCallback(e);
    },
    _onBtnLeave(e) {
      if (this.disabled) {
        return;
      }
      if (this.trigger !== 'hover') {
        return;
      }
      if (this.role) {
        this.timeout = setTimeout(() => { this.isHidden = true; }, 100);
      }
      const to = e.toElement;
      if (!to) {
        return;
      }
      const isDropdown = to.closest(`#${this.innerId} .bp-dropdown__btn`) || to.closest(`#${this.innerId} .bp-dropdown__body`);
      if (isDropdown) {
        return;
      }
      this.prepare();
    },
    _onBodyClick() {
      if (this.closeOnClick) {
        this.isHidden = true;
      }
    },
    _onBodyEnter() {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
    },
    _onBodyLeave(e) {
      if (this.teleportToBody) {
        const related = e.relatedTarget;
        const isOnDropdownIcon = this.$refs.dropdown?.contains(related);

        if (isOnDropdownIcon) return;

        this.isHidden = true;
        this.resetPosition();
        this.$emit('dropdown-leave');
        return;
      }

      if (this.trigger !== 'hover') {
        return;
      }
      const to = e.toElement;
      if (!to) {
        return;
      }
      if (to.closest('.bp-dropdown__btn') || to.closest('.bp-dropdown__sub')) {
        return;
      }
      this.prepare();
    },
    checkCustomCallback(e) {
      if (!this.isHidden) {
        this.prepare();
        return;
      }

      // --- custom callback before open
      const promise = new Promise(resolve => {
        this.isLoading = true;
        // eslint-disable-next-line no-useless-call
        this.beforeOpen.call(this, resolve);
      });
      promise.then(() => {
        this.isLoading = false;
        if (!e.target.closest('.bp-dropdown__body')) {
          // --- hide dropdown if other dropdowns show
          this.$root.$emit('bp-dropdown:open');
        }
        setTimeout(this.prepare, 0);
      });
      promise.catch(() => { throw Error('bp-dropdown promise error'); });
    },
    prepare() {
      this.isHidden = !this.isHidden;

      if (this.teleportToBody) {
        this.isHidden ? this.resetPosition() : this.moveToBody();
        return;
      }

      if (!this.isHidden) {
        this.$nextTick(() => {
          const button = this.$el.firstElementChild;
          const container = document.getElementById(this.idDropdown);
          this.setWidth(button.offsetWidth);
          // next tick, чтобы успела примениться минимальная ширина контейнера
          this.$nextTick(() => {
            this.setPosition(button, container);
          });
        });
      }
    },
    setWidth(width) {
      this.width = width;
    },
    setPosition(btn, body) {
      if (!btn || !body) {
        return;
      }
      let rect;
      // TODO Доделать auto позиционирование 18.12.2019
      if (this.align === 'auto') {
        console.warn('Автоматическое позиционирование в данный момент не поддерживается');
      } else {
        rect = this.calcRectFromAlign(btn, body, this.align);
      }
      this.stylePosition = rect;
    },
    calcRectFromAlign(btn, body, align) {
      // --- body size
      const bodyWidth = body.offsetWidth;
      // --- btn size
      const btnWidth = btn.offsetWidth;
      const btnHeight = btn.offsetHeight;
      let _top, _left, _right, _bottom;
      switch (align) {
        case 'top':
        case 'top-right':
          _bottom = btnHeight;
          _left = 0;
          break;
        case 'top-left':
          _bottom = btnHeight;
          _right = -btnWidth;
          break;
        case 'right':
        case 'right-bottom':
          _top = -btnHeight;
          _left = btnWidth;
          break;
        case 'right-top':
          _bottom = 0;
          _left = btnWidth;
          break;
        case 'bottom-left':
          _top = 0;
          _right = -btnWidth;
          break;
        case 'bottom-center':
          _top = 0;
          _left = (btnWidth - bodyWidth) / 2;
          break;
        case 'left':
        case 'left-bottom':
          _top = -btnHeight;
          _right = 0;
          break;
        case 'left-top':
          _bottom = 0;
          _right = 0;
          break;
        case 'bottom':
        case 'bottom-right':
        default:
          _top = 0;
          _left = 0;
          break;
      }
      _top += this.y;
      _left += this.x;
      return { top: _top + 'px', left: _left + 'px', bottom: _bottom + 'px', right: _right + 'px' };
    },
    // используется через $ref компонента
    close() {
      this.$root.$emit('bp-dropdown:hide');
    },
    moveToBody() {
      this.$nextTick(() => {
        if (!this.$refs.dropdownBody) return;

        if (!this.dropdownContainer) {
          this.dropdownContainer = document.createElement('div');
          this.dropdownContainer.classList.add('dropdown-container');
          document.body.appendChild(this.dropdownContainer);
        }

        const rect = this.$refs.dropdown.getBoundingClientRect();

        Object.assign(this.dropdownContainer.style, {
          position: 'absolute',
          top: `${rect.bottom + window.scrollY}px`,
          left: `${rect.left + window.scrollX}px`,
          minWidth: `${this.width}px`,
        });

        this.dropdownContainer.appendChild(this.$refs.dropdownBody);
      });
    },
    resetPosition() {
      if (this.dropdownContainer && this.$refs.dropdownBody) {
        this.dropdownContainer.remove();
        this.dropdownContainer = null;
      }
    },
  },
};
</script>

<style scoped>
.bp-dropdown{
  position: relative;
}
.bp-dropdown--sub {
    width: 100%;
}
.bp-dropdown--sub .bp-dropdown__btn,
.bp-dropdown--sub .bp-dropdown__sub {
    width: 100%;
}
.bp-dropdown--sub .bp-dropdown__icon {
    margin-left: auto;
}
.bp-dropdown__btn {
    display: inline-flex;
    align-items: center;
    padding: 3px 5px;
    border: 1px solid #efefef;
    cursor: pointer;
    transition: background-color .1s ease;
}
.bp-dropdown__sub {
    display: inline-flex;
    align-items: center;
}
.bp-dropdown__btn--active {
    background-color: #eee;
}
.bp-dropdown__btn--disabled {
    opacity: 0.5;
    cursor: not-allowed;
}
.bp-dropdown__icon {
    display: flex;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    overflow: visible;
    transition: transform .1s ease;
}
.bp-dropdown__icon--spin {
    width: 12px;
    height: 12px;
    animation: spin 2s infinite linear;
}
.bp-dropdown__icon--top {
    transform: rotate(-180deg);
}
.bp-dropdown__icon--right {
    transform: rotate(-90deg);
}
.bp-dropdown__icon--bottom {
    transform: rotate(0);
}
.bp-dropdown__icon--left {
    transform: rotate(-270deg);
}
.bp-dropdown__btn--active .bp-dropdown__icon--top,
.bp-dropdown__sub--active .bp-dropdown__icon--top {
    transform: rotate(0);
}
.bp-dropdown__btn--active .bp-dropdown__icon--right,
.bp-dropdown__sub--active .bp-dropdown__icon--right {
    transform: rotate(-270deg);
}
.bp-dropdown__btn--active .bp-dropdown__icon--bottom,
.bp-dropdown__sub--active .bp-dropdown__icon--bottom,
.bp-dropdown__btn--active .bp-dropdown__icon--bottom-left,
.bp-dropdown__sub--active .bp-dropdown__icon--bottom-left {
    transform: rotate(-180deg);
}
.bp-dropdown__btn--active .bp-dropdown__icon--left,
.bp-dropdown__sub--active .bp-dropdown__icon--left {
    transform: rotate(-90deg);
}

.bp-dropdown__helper{
    overflow: visible;
    position: absolute;
}
.bp-dropdown__body {
    position: absolute;
    padding: 6px 8px;
    background-color: #fff;
    box-shadow: 0 5px 15px -5px rgba(0, 0, 0, .5);
    z-index: 9999;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .1s;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}

.dropdown-container > div {
  padding: 0;
  border-bottom: 1px solid #e9e9e9;
  border-radius: .4rem;
  width: 120px;
}

.dropdown-container li {
  font-size: 13px;
}

@keyframes spin {
    0% {
        transform:rotate(0)
    }
    100% {
        transform:rotate(360deg)
    }
}
</style>
